body {
    font-family: Arial, sans-serif;
    margin: 20px;
}

h1 {
    text-align: center;
}

select {
    margin-bottom: 20px;
    padding: 5px;
}

.table-container {
    margin-top: 20px;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

th {
    background-color: #f2f2f2;
    text-align: left;
}

td {
    cursor: pointer;
}

button {
    margin: 5px;
    padding: 5px 10px;
}

.pagination {
    margin-top: 10px;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    background-color: rgba(0,0,0,0.5);
    display:flex;
    align-items:center;
    justify-content:center;
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
}

.close-button {
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
    font-size: 24px;
}

/* Existing styles... */

.filter-container {
    margin: 10px 0;
}

.filter-container select,
.filter-container input {
    margin-right: 10px;
    padding: 5px;
}

.filter-container button {
    padding: 5px 10px;
}

